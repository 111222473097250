<template>
  <div class="ls-product-card">
    <router-link :to="{ name: 'Product', params: { slug: product.slug } }">
      <div class="row">
        <div class="col-12"><img v-lazy="product.images[0].path" /></div>
        <div class="col-12">
          <a class="ps-product__vendor">
            {{ product.name }}
            <span><b>${{ product.price }}</b></span>
          </a>
        </div>
        <div class="col-12">
          <router-link :to="'/shop/' + product.shop.slug">
            <h4>{{ product.shop.name }}</h4>
          </router-link>
        </div>
      </div>
    </router-link>
    <div v-if="!inCart" class="col-12">
      <button class="btn ls-button" @click="addToCart()">
        Add to cart
      </button>
    </div>
    <div v-else class="col-12">
      <button class="btn ls-button" @click="goToCheckout()">
        Buy Now
      </button>
    </div>
  </div>
</template>
<script>
import global from "@/mixins/global.js";
import { mapState } from "vuex";
import axios from "axios";
export default {
  props: ["product"],
  data() {
    return {
      loading: false,
      inCart: false,
    };
  },
  computed: {
    imagePath() {
      if (this.product.images && this.product.images.length > 0) {
        return this.product.images[0].path;
      } else {
        return "";
      }
    },
    ...mapState({
      auth: (state) => state.auth,
      cart: (state) => state.cart,
    }),
    productColors() {
      // console.log(this.product.properties);
      // return this.product.properties.filter(prop => prop.color)
    },
    productSizes() {
      // console.log(this.product.properties);
      // return this.product.properties.filter(prop => prop.size)
    },
  },
  mixins: [global],
  mounted() {
    // console.log("Cart", this.cart.cartItems);
    this.cart.cartItems.forEach(product => {
      if (product.id === this.product.id) {
        this.inCart = true;
      }
    });
  },
  methods: {
    addToCart() {
      this.loading = true;
      let newProp = {
        qty: 1,
      };
      if (this.product.properties.hasOwnProperty("color")) {
        newProp["color"] = this.product.properties.color;
      }
      if (this.product.properties.hasOwnProperty("size")) {
        newProp["size"] = this.product.properties.size;
      }

      // this.$store.dispatch("cart/add", {
      //   product: this.product,
      //   quantity: 1,
      // });
      

      this.$store.dispatch("cart/addToCart", {
        product: this.product,
        quantity: 1,
      });
      this.inCart = true;
      // this.add(this.product, newProp);
      //this.$notification.new("Product Added To Cart", { timer: 10 });
      this.$toasted.success("Product Added To Cart");
      // setTimeout(() => {
      //   this.loading = false;
      //   location.reload();
      // }, 2000);

      let miniCartEl = document.getElementById("mini-cart");

      // if (miniCartEl && miniCartEl.length > 0) {
      //     // $('html, body').animate({scrollTop:$('#mini-cart').position().top}, 'slow');
      //     miniCartEl.style.visibility = "visible";
      //     miniCartEl.style.opacity = "1";
      //     setTimeout(() => {
      //       miniCartEl.style.visibility = "hidden";
      //       miniCartEl.style.opacity = "0";
      //     }, 3000);
      // }
    },
    goToCheckout() {
      this.$router.push({ name: "cart" });
    },
  },
};
</script>
