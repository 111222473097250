<template>
  <div class="ps-page--single ps-page--vendor">
    <section class="ps-store-list">
      <div class="ps-container">
        <aside class="ps-block--store-banner">
          <div
            class="ps-block__content bg--cover"
            :style="`background-image: url(${shop.image_path}); width: 100%; background-repeat: no-repeat;`"
          >
          <div v-if="showMyDiv">

            <a class="ps-block__inquiry" href="#">

             
              
                <i class="fa fa-question"></i> Inquiry</a>
                

              </div>
          
          </div>
          <div  v-if="showMyDiv" class="ps-block__user">
            <div class="ps-block__user-avatar" id="user_profile_image" hidden>
              <img src="../../assets/user.png" alt="User" />
            </div>
            <div class="ps-block__user-content">
              <h3 class="shop-name">{{ shop.name }}</h3>
              <p>
                <i class="icon-envelope"></i>
                <a href="" class="__cf_email__">{{ shop.name }}</a>
              </p>
              <p v-if="shop.address">
                <i class="icon-map-marker"></i>{{ shop.address.street }},
                {{ shop.address.city }}, {{ shop.address.country }}
              </p>
              <p v-if="shop.user">
                <i class="icon-envelope"></i>
                <a href="" class="__cf_email__">{{ shop.user.email }}</a>
              </p>
            </div>
          </div>
        </aside>
        <div class="ps-section__wrapper">
          <!-- <div v-show="shouldShowDiv" class="ps-section__left">
            <aside class="widget widget--vendor">
              <h3 class="widget-title">Product Search</h3>
              <div class="form-group--icon">
                <input
                  class="form-control"
                  type="text"
                  placeholder="Search..."
                /><i class="icon-magnifier"></i>
              </div>
            </aside>
            <aside class="widget widget--vendor">
              <h3 class="widget-title">Store Categories</h3>
              <ul class="ps-list--arrow">
                <li
                  v-for="category in storeCategories"
                  :key="category.id"
                  class="menu-item-has-children"
                >
                  <a href="#">{{ category.name }}</a>
                  <ul class="sub-menu ps-list--arrow">
                    <li><a href="#"> Custom Grilles</a></li>
                  </ul>
                </li>
              </ul>
            </aside>
            <aside class="widget widget--vendor widget--open-time">
              <h3 class="widget-title">
                <i class="icon-clock3"></i> Store Hours
              </h3>
              <ul>
                <li v-for="hour in shop.hours" :key="hour.id">
                  <strong>{{ hour.day_of_week }}:</strong
                  ><span
                    >{{ hour.start_hour }} am - {{ hour.end_hour }} pm</span
                  >
                </li>
              </ul>
            </aside>
          </div> -->
          <div class="ps-section__right" style="margin-top: -92px;">
            <!-- <nav class="ps-store-link">
              <ul>
                <li class="active"><a href="#">Products</a></li>
                <li><a href="#">About</a></li>
                <li><a href="#">Policies</a></li>
                <li><a href="#">Reviews(0)</a></li>
              </ul>
            </nav> -->
            <div class="ps-shopping ps-tab-root" >
              <div class="ps-shopping__header">
                <p>
                  <strong> {{ count }}</strong> Products found
                </p>
                <div class="ps-shopping__actions">
                  <div class="ps-shopping__view">
                    <p>View</p>
                    <ul class="ps-tab-list">
                      <li id="icon_grid" class="active">
                        <a href="#tab-1" @click.prevent="gridView()"
                          ><i class="icon-grid"></i
                        ></a>
                      </li>
                      <li id="icon_list">
                        <a href="#tab-2" @click.prevent="listView()"
                          ><i class="icon-list4"></i
                        ></a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="ps-tabs">
                <div class="ps-tab active" id="tab-1">
                  <div class="ps-shopping-product">
                    <div class="row">
                      <div
                        v-for="product in products"
                        :key="product.id"
                        class="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-6"
                      >
                        <treat-card v-if="needQuote(product)" :product="product"></treat-card>
                        <product-card v-else :product="product"></product-card>
                      </div>
                    </div>
                  </div>
                  <!-- <div class="ps-pagination">
                    <ul class="pagination">
                      <li class="active"><a href="#">1</a></li>
                      <li><a href="#">2</a></li>
                      <li><a href="#">3</a></li>
                      <li>
                        <a href="#"
                          >Next Page<i class="icon-chevron-right"></i
                        ></a>
                      </li>
                    </ul>
                  </div> -->
                </div>
                <div class="ps-tab" id="tab-2">
                  <div class="ps-shopping-product">
                    <list-product-card
                      v-for="product in products"
                      :key="product.id"
                      :product="product"
                    ></list-product-card>
                  </div>
                </div>

                <div class="table-responsive">
                  <table class="table ps-table ps-table--vendor">
                    <tfoot>
                      <tr v-if="(!meta || meta.total === 0) && !isLoading">
                        <td colspan="7" class="text-center m-4 text-danger p-4">
                          No Listings available
                        </td>
                      </tr>
                      <tr v-else-if="isLoading">
                        <td colspan="7" class="text-center m-4 text-danger">
                          Loading listings...
                        </td>
                      </tr>
                      <tr v-else>
                        <td class="text-right" colspan="7">
                          <ul class="pagination text-right">
                            <li v-on:click="goToPrevPage()" v-if="hasPrevPage">
                              <a href="#"
                                >Prev Page<i class="icon-chevron-left"></i
                              ></a>
                            </li>
                            <li class="active">
                              <a href="#">Page {{ pageNumber }}</a>
                            </li>
                            <!--                      <li><a href="#">3</a></li>-->
                            <li v-on:click="goToNextPage()" v-if="hasNextPage">
                              <a href="#"
                                >Next Page<i class="icon-chevron-right"></i
                              ></a>
                            </li>
                          </ul>
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <h5 style="margin-top: -77px; font-weight: 2000;" class="ls-center">
              {{ pageTitle }}
            </h5>
          <nav class="ph-quick-links ps-store-link">
            <div class="row ls-center">
              <div class="col-3">
                <a href="pahukama-supermarket">
                  <img src="/img/groceries.png" />
                  <p>Groceries</p>
                </a>
              </div>
              <div class="col-3">
                <a href="pahukama-butchery">
                  <img src="/img/butchery.png" />
                  <p>Butchery</p>
                </a>
              </div>
              <div class="col-3">
                <a href="pahukama-liquor">
                  <img src="/img/liquor.png" />
                  <p>Liquor Store</p>
                </a>
              </div>
              <div class="col-3">
                <a href="pahukama-bakery">
                  <img src="/img/bakery.png" />
                  <p>Bakery</p>
                </a>
              </div>
              <div class="col-3">
                <a href="pahukama-greens">
                  <img src="/img/vegitables.png" />
                  <p>Greens</p>
                </a>
              </div>
              <div class="col-3">
                <a href="pahukama-hardware">
                  <img src="/img/hardware.png" />
                  <p>Hardware</p>
                </a>
              </div>
              <div class="col-3">
                <a href="/get-runner">
                  <img src="/img/sweets.png" />
                  <p>Treats</p>
                </a>
              </div>
              <div class="col-3">
                <a href="pahukama-takeaways">
                  <img src="/img/giftset.png" />
                  <p>Takeaways</p>
                </a>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import ProductCard from "@/components/Cards/ProductCard.vue";
import TreatCard from "@/components/Cards/TreatCard.vue";
import ListProductCard from "@/components/Cards/ListProductCard.vue";
import axios from "axios";
import global from "@/mixins/global";
export default {
  components: { ProductCard, ListProductCard , TreatCard},
  mixins: [global],
  data() {
    return {
      showMyDiv: true,
      shop: {},
      storeCategories: [],
      products: [],
      updatedProducts: [],
      isLoading: false,
      meta: null,
      isLoading: false,
      links: {},
      url: null,
      hasNextPage: false,
      nextPage: null,
      hasPrevPage: false,
      previousPage: null,
      pageNumber: 1,
      count: 0,
    };
  },
  computed: {
    shouldShowDiv() {
      // Return a boolean indicating whether the div should be shown or not
      return this.currentPage !== 'valentine';
    },
    pageTitle() {
      // Check the current route and return the appropriate page title
      if (this.$route.name === 'shop') {
        return 'Continue Valentine Shopping from any of our Stores and it’s READY on V day';
      } else {
        return 'Continue Shopping';
      }
    },
  },
  
  mounted() {
    this.isLoading = true;
    this.getShop();

    if (this.$route.name === 'shop') {
      this.showMyDiv = false;
    }
  },
  watch: {
    $route(to, from) {
      //get shop products when link changes
      this.getShop();
    },
  },
  methods: {
    needQuote(product) {
      var shopArray = [
        'pahukama-treats',
        'pahukama-township'
      ];

      if (shopArray.includes(product.shop.slug) && product.price == '1.00') {
          return true;
      } else {
        return false;
      }
    },
    listView() {
      if (document.getElementById("tab-2").classList.contains("active")) {
        return "";
      } else {
        document.getElementById("tab-1").classList.remove("active");
        document.getElementById("tab-2").classList.add("active");
        document.getElementById("icon_list").classList.add("active");
        document.getElementById("icon_grid").classList.remove("active");
      }
    },


    gridView() {
      if (document.getElementById("tab-1").classList.contains("active")) {
        return "";
      } else {
        document.getElementById("tab-2").classList.remove("active");
        document.getElementById("tab-1").classList.add("active");
        document.getElementById("icon_grid").classList.add("active");
        document.getElementById("icon_list").classList.remove("active");
      }
    },

    getShop() {
      var that = this;
      axios.get(`shops/${this.$route.params.slug}`).then((response) => {
        that.shop = response.data;
        this.getShopProducts(that.shop.id);
        //this.getShopCategories(this.shop.category.id);
        this.isLoading = false;
      });
    },
    getShop2(url) {
      var that = this;
      axios.get(`shops/${this.$route.params.slug}`).then((response) => {
        that.shop = response.data;
        this.getShopProducts2(that.shop.id);
        //this.getShopCategories(this.shop.category.id);
        this.isLoading = false;
      });
    },

    getShopCategories(shopCategoryId) {
      axios
        .get(`categories?shopcategory=${shopCategoryId}`)
        .then((response) => {
          this.storeCategories = response.data;
        });
    },

    getShopCategories2() {
      axios
        .get(`categories`)
        .then((response) => {
          this.storeCategories = response.data;
        });
    },

    getShopProducts(shopId) {
      axios.get(`products?shop=${shopId}`).then((response) => {
        let oldProducts = response.data.data;
        this.products.length = 0;
        oldProducts.forEach((product) => {
          if (product.images && product.images.length > 0) {
            this.products.push(product);
          }
        });
        this.meta = response.data.meta;
        this.links = response.data.links;
        this.count = response.data.meta.total;
        //this.count = this.products.length;
        this.pageNumber = response.data.meta.current_page;
        this.handleNextPage(response.data.links);
        this.handlePrevPage(response.data.links);
      });
    },

    getShopProducts2(shopId) {
      //Next page products
      axios.get(this.url + "&shop=" + shopId).then((response) => {
        let oldProducts = response.data.data;
        this.products.length = 0;
        oldProducts.forEach((product) => {
          if (product.images && product.images.length > 0) {
            this.products.push(product);
          }
        });
        this.meta = response.data.meta;
        this.links = response.data.links;
        this.count = response.data.meta.total;
        //this.count = this.products.length;
        this.pageNumber = response.data.meta.current_page;
        this.handleNextPage(response.data.links);
        this.handlePrevPage(response.data.links);
      });
    },

    goToNextPage: function () {
      this.url = this.nextPage;
      if (this.url) {
        //this.getShop2(this.url);
        this.getShopProducts2(this.shop.id);
      }
      //this.scrollTop();
    },

    goToPrevPage: function () {
      this.url = this.prevPage;
      if (this.url) {
        //this.getShop2(this.url);
        this.getShopProducts2(this.shop.id);
      }
      //this.scrollTop();
    },

    handleNextPage(links) {
      if (links.next) {
        this.hasNextPage = true;
        this.nextPage = links.next;
      } else {
        this.hasNextPage = false;
      }
    },

    handlePrevPage(links) {
      if (links.prev) {
        this.hasPrevPage = true;
        this.prevPage = links.prev;
      } else {
        this.hasPrevPage = false;
      }
    },
  },
};
</script>
<style scoped>
.ph-quick-links p {
  color: black;
}
.ph-quick-links {
  background-color: #fda31e;
}

.ph-quick-links img {
  max-width: 50px;
  width: 100%;
}
.row-2 {
  background-color: white;
  margin-top: -50px;
  margin-bottom: 0px;
}
</style>
